_document.ready(function () {

    $( "#contact-form" ).submit(function( event ) {
        event.preventDefault();

        var contactForm = $(this),
            fieldsArray = contactForm.serializeArray(),
            formData = {},
            resultBox = contactForm.find('.form__messages'),
            url = "/send.php";
        
        fieldsArray.forEach(function (item) {
            formData[item.name] = item.value;
        });

        resultBox.hide();
        resultBox.html("");

        var $btn = contactForm.find('button[type=submit]').button('loading');
        
        setTimeout(function () {
            $btn.button('reset');
        }, 2000);
        
        grecaptcha.execute('6Ld4nw0fAAAAAGh_maVk72u6zUczCxLvwv-VGUjn', {action: 'homepage'})
            .then(function(token) {
                formData['recaptcha_response'] = token;
                $.post(url, formData, function (data) {
                    setTimeout(function () {
                        if (data.result === 'success') {
                            resultBox.addClass('form__messages--success').removeClass('form__messages--error');
                            resultBox.append("<p>Thank you. Your message has been sent successfully.</p>");
                            contactForm[0].reset();
                            app.isFilled.checkAllFields();
                        } else if (data.result === 'error') {
                            resultBox.addClass('form__messages--error').removeClass('form__messages--success');
                            if (data.errors === undefined) {
                                resultBox.append("<p>There was an error sending, please try again later.</p>");
                            } else {
                                for (var key in data.errors) {
                                    resultBox.append("<p>" + data.errors[key] + "<p>");
                                }
                            }
                        }
                        resultBox.show();
                        $btn.button('reset');
                    }, 2000);
                }, 'json');
            }, function (err) {
                $btn.button('reset');
                resultBox.append("<p>There was an error sending, please try again later.</p>");
                resultBox.show();
            });
    });
});
